.shoppable-icon {
  &.color {
    &-current {
      fill: currentColor;
    }

    &-main {
      fill: var(--main-color);
    }

    &-default {
      fill: white;
    }

    &-white {
      fill: white;
    }

    &-black {
      fill: black;
    }

    &-gray {
      fill: #CFC8BB;
    }

    &-green {
      fill: #08b273;
    }

    &-red {
      fill: #d83a68;
    }

    &-black4 {
      fill: rgba(0,0,0,0.4);
    }

    &-black44 {
      fill: rgba(0,0,0,0.44);
    }

    &-black64 {
      fill: rgba(0,0,0,0.64);
    }
  }
  &.size {
    &-default {
      width: 20px;
      height: 20px;
    }

    &-10 {
      width: 10px;
      height: 10px;
    }

    &-13 {
      width: 13px;
      height: 13px;
    }

    &-16 {
      width: 16px;
      height: 16px;
    }

    &-20 {
      width: 20px;
      height: 20px;
    }

    &-24 {
      width: 24px;
      height: 24px;
    }

    &-25 {
      width: 25px;
      height: 25px;
    }

    &-25_36 {
      width: 25px;
      height: 36px;
    }

    &-29 {
      width: 29px;
      height: 29px;
    }

    &-30 {
      width: 30px;
      height: 30px;
    }

    &-35 {
      width: 35px;
      height: 35px;
    }

    &-36 {
      width: 36px;
      height: 36px;
    }

    &-40 {
      width: 40px;
      height: 40px;
    }

    &-42 {
      width: 42px;
      height: 42px;
    }

    &-48 {
      width: 48px;
      height: 48px;
    }

    &-50 {
      width: 50px;
      height: 50px;
    }

    &-60 {
      width: 60px;
      height: 60px;
    }
  }
}
